import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    callback: "",
    nextVisible: true,
    nextEnabled: true,
    previousVisible: true,
    previousEnabled: false,
    currentStep: 1,
    apptId: 0,
    customerName: "",
    customerEmail: "",
    customerPhone: "",
    vehicleBrand: "",
    vehicleImmat: "",
    vehicleVIN: "",
    vehicleMileage: "",
    vehicleModel: "",
    cgvAccepted: false,
    keyDropKey: false,
    keyDropPaper: false,
    keyDropRestitution: false,
    apptType: "APV",
    direction: "empty",
    ratingEasy: 0,
    ratingReuse: 0,
    ratingRecommend: 0,
    lang: "fr",
    defaultLang: "fr",
    langs: "",
    tenant_short: "",
    api: false,
    position: -1,
    last_movement: -1,
    not_sync: -1,
    lockers: -1,
    free_lockers: -1,
    display_modal: "",
    modal: false,
    locked: false,
    hasMotors: true,
    loaded: false,
    timeout: "not yet",
    skin: "ips",
  },
  mutations: {
    loadstatus: (
      state,
      { api, position, last_movement, not_sync, lockers, free_lockers }
    ) => {
      state.api = api;
      state.position = position;
      state.last_movement = last_movement;
      state.not_sync = not_sync;
      state.lockers = lockers;
      state.free_lockers = free_lockers;
    },
    modal_lock: (state) => {
      state.modal = true;
      state.locked = true;
    },
    modal_unlock: (state) => {
      state.modal = false;
    },
    modal: (state, { msg }) => {
      state.display_modal = msg;
    },
    nextStep: (state) => {
      state.currentStep++;
    },
    previousStep: (state) => {
      state.currentStep--;
    },
    init: (
      state,
      {
        apptId,
        customerName,
        customerEmail,
        customerPhone,
        customerComment,
        vehicleBrand,
        vehicleModel,
        vehicleImmat,
        vehicleVIN,
        vehicleMileage,
        cgvAccepted,
        keyDropKey,
        keyDropPaper,
        keyDropRestitution,
        apptType,
        ratingEasy,
        ratingRecommend,
        ratingReuse,
        direction,
        hasMotors,
      }
    ) => {
      state.apptId = apptId;
      state.customerName = customerName;
      state.customerEmail = customerEmail;
      state.customerPhone = customerPhone;
      state.customerComment = customerComment;
      state.vehicleBrand = vehicleBrand;
      state.vehicleModel = vehicleModel;
      state.vehicleImmat = vehicleImmat;
      state.vehicleVIN = vehicleVIN;
      state.vehicleMileage = vehicleMileage;
      state.cgvAccepted = cgvAccepted;
      state.keyDropKey = keyDropKey;
      state.keyDropPaper = keyDropPaper;
      state.keyDropRestitution = keyDropRestitution;
      state.ratingEasy = ratingEasy;
      state.ratingRecommend = ratingRecommend;
      state.ratingReuse = ratingReuse;
      state.apptType = apptType;
      state.direction = direction;
      if (state.direction == "OUT") {
        state.currentStep = 301;
      }
      state.hasMotors = hasMotors;
      state.loaded = true;
    },
    loadShopData: (
      state,
      { tenant_short, shop, langs, defaultLang, defaultSkin }
    ) => {
      state.tenant_short = tenant_short;
      state.shop = shop;
      state.langs = langs;
      state.defaultLang = defaultLang;
      state.lang = defaultLang;
      state.skin = defaultSkin;
    },
    callback: (state, { callback }) => {
      state.callback = callback;
    },
    unlock: (state) => {
      state.locked = false;
    },

    customerEmail: (state, { email }) => {
      state.customerEmail = email;
    },
    customerPhone: (state, { phone }) => {
      state.customerPhone = phone;
    },
    enableNext: (state) => {
      state.nextEnabled = true;
    },
    disableNext: (state) => {
      state.nextEnabled = false;
    },
    enablePrevious: (state) => {
      state.previousEnabled = true;
    },
    disablePrevious: (state) => {
      state.previousEnabled = false;
    },
    customerComment: (state, { comment }) => {
      state.customerComment = comment;
    },
    cgvAccepted: (state) => {
      state.cgvAccepted = true;
    },
    cgvNotAccepted: (state) => {
      state.cgvAccepted = false;
    },
    keyDropKey: (state) => {
      state.keyDropKey = true;
    },
    keyDropNotKey: (state) => {
      state.keyDropKey = false;
    },
    keyDropPaper: (state) => {
      state.keyDropPaper = true;
    },
    keyDropNotPaper: (state) => {
      state.keyDropPaper = false;
    },
    keyDropRestitution: (state) => {
      state.keyDropRestitution = true;
    },
    keyDropNotRestitution: (state) => {
      state.keyDropRestitution = false;
    },
    ratings: (state, { easy, reuse, recommend }) => {
      state.ratingEasy = easy;
      state.ratingReuse = reuse;
      state.ratingRecommend = recommend;
    },
    vehicleMileage: (state, { mileage }) => {
      state.vehicleMileage = mileage;
    },
    lang: (state, { lang }) => {
      state.lang = lang;
    },
    timeout: (state, { timeout }) => {
      state.timeout = timeout;
    },
    updateSkin: (state, { skin }) => {
      state.skin = skin;
    },
  },
  actions: {
    loadAppt({ commit }, { id, has_motors }) {
      axios
        .get(process.env.VUE_APP_API_BASE + "/appointment/" + id)
        .then((response) =>
          commit("init", {
            apptId: id,
            customerName: response.data.customerName,
            customerEmail: response.data.customerEmail,
            customerPhone: response.data.customerPhone,
            customerComment: response.data.customerComment,
            vehicleBrand: response.data.vehicleBrand,
            vehicleModel: response.data.vehicleModel,
            vehicleImmat: response.data.vehicleImmat,
            vehicleVIN: response.data.vehicleVIN,
            vehicleMileage: response.data.vehicleMileage,
            cgvAccepted: response.data.cgvAccepted,
            keyDropKey: response.data.keyDropKey,
            keyDropPaper: response.data.keyDropPaper,
            keyDropRestitution: response.data.keyDropRestitution,
            apptType: response.data.apptType,
            ratingEasy: response.data.ratingEasy,
            ratingReuse: response.data.ratingReuse,
            ratingRecommend: response.data.ratingRecommend,
            direction: response.data.direction,
            hasMotors: has_motors,
          })
        );
    },
    loadApptExt({ commit }, { id, has_motors }) {
      axios
        .get(process.env.VUE_APP_API_BASE + "/appointment/ext/" + id)
        .then((response) =>
          commit("init", {
            apptId: response.data.id,
            customerName: response.data.customerName,
            customerEmail: response.data.customerEmail,
            customerPhone: response.data.customerPhone,
            customerComment: response.data.customerComment,
            vehicleBrand: response.data.vehicleBrand,
            vehicleModel: response.data.vehicleModel,
            vehicleImmat: response.data.vehicleImmat,
            vehicleVIN: response.data.vehicleVIN,
            vehicleMileage: response.data.vehicleMileage,
            cgvAccepted: response.data.cgvAccepted,
            keyDropKey: response.data.keyDropKey,
            keyDropPaper: response.data.keyDropPaper,
            keyDropRestitution: response.data.keyDropRestitution,
            apptType: response.data.apptType,
            ratingEasy: response.data.ratingEasy,
            ratingReuse: response.data.ratingReuse,
            ratingRecommend: response.data.ratingRecommend,
            direction: response.data.direction,
            hasMotors: has_motors,
          })
        );
    },

    loadCallback({ commit }, { callback }) {
      commit("callback", { callback: callback });
    },
    sync({ state }) {
      axios.put(
        process.env.VUE_APP_API_BASE + "/appointment/" + state.apptId,
        state
      );
    },
    loadstatus({ commit, state }) {
      if (state.locked) {
        commit("unlock");
      } else {
        axios
          .get(process.env.VUE_APP_API_BASE + "/status")
          .then((response) => {
            commit("loadstatus", {
              api: true,
              position: response.data.location,
              last_movement: response.data.lastmov,
              not_sync: response.data.notSynced,
              lockers: response.data.lockers,
              free_lockers: response.data.free_lockers,
            });
            if (response.data.lastmov == "48") {
              commit("modal", { msg: "" });
            } else {
              axios.get("http://localhost:3000/front");
            }
          })
          .catch(() =>
            commit("loadstatus", {
              api: false,
              position: "UNK",
              last_movement: "UNK",
            })
          );
      }
    },
    loadShopData({ commit }) {
      return new Promise((resolve) => {
        axios.get(process.env.VUE_APP_API_BASE + "/config").then((resp) => {
          commit({
            type: "loadShopData",
            tenant_short: resp.data.tenant_short,
            shop: resp.data.shop_name,
            langs: resp.data.langs,
            defaultLang: resp.data.default_lang,
            defaultSkin: resp.data.default_skin,
          });
          resolve();
        });
      });
    },
  },
  modules: {},
  getters: {
    nextVisible: (state) => {
      return state.nextVisible;
    },
    nextEnabled: (state) => {
      return state.nextEnabled;
    },
    skin: (state) => {
      return state.skin;
    },
  },
});
