import Vue from "vue";
import VueRouter from "vue-router";
const Welcome = () =>
  import(/* webpackChunkName: "home" */ "../views/Welcome.vue");
const CustomerInfo = () =>
  import(/* webpackChunkName: "custinfo" */ "../views/CustomerInfo.vue");
const VehicleInfo = () =>
  import(/* webpackChunkName: "vehicleinfo" */ "../views/VehicleInfo.vue");
const Comments = () =>
  import(/* webpackChunkName: "comments" */ "../views/Comments.vue");
const Reminders = () =>
  import(/* webpackChunkName: "reminders" */ "../views/Reminders.vue");
const KeyDrop = () =>
  import(/* webpackChunkName: "keydrop" */ "../views/KeyDrop.vue");
const KeyPick = () =>
  import(/* webpackChunkName: "keypick" */ "../views/KeyPick.vue");
const Advisor = () =>
  import(/* webpackChunkName: "advisor" */ "../views/Advisor.vue");
const Confirmation = () =>
  import(/* webpackChunkName: "confirmation" */ "../views/Confirmation.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/cust",
    name: "CustomerInfo",
    component: CustomerInfo,
  },
  {
    path: "/vehicle",
    name: "VehicleInfo",
    component: VehicleInfo,
  },
  {
    path: "/comments",
    name: "Comment",
    component: Comments,
  },
  {
    path: "/reminders",
    name: "Reminders",
    component: Reminders,
  },
  {
    path: "/keydrop",
    name: "KeyDrop",
    component: KeyDrop,
  },
  {
    path: "/keypick",
    name: "KeyPick",
    component: KeyPick,
  },
  {
    path: "/advisor",
    name: "Advisor",
    component: Advisor,
  },
  {
    path: "/confirmation",
    name: "Confirmation",
    component: Confirmation,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
