<template>
  <v-app :class="$store.getters.skin">
    <v-app-bar app :class="$store.getters.skin">
      <div class="d-flex align-center" :class="$store.getters.skin">
        APV <v-btn v-if="debugmode" @click="goback">FR</v-btn
        ><v-btn v-if="debugmode" @click="goback2">ES</v-btn
        ><v-btn v-if="debugmode" @click="goback3">US</v-btn
        ><v-btn v-if="debugmode" @click="goback5">Peugeot</v-btn
        ><v-btn v-if="debugmode" @click="goback7">IPSIP</v-btn
        ><v-btn v-if="debugmode" @click="goback4">Citroen</v-btn>
      </div>

      <v-spacer></v-spacer>
      <div>
        <span
          v-if="$store.state.hasMotors && $store.state.last_movement != '48'"
          style="color: #ff0000"
          ><strong>{{ $t("locker.moving") }}</strong></span
        >
      </div>
      <v-spacer></v-spacer>
      <div>Version: {{ version }}</div>
    </v-app-bar>

    <v-main>
      <v-overlay
        v-if="
          $store.state.hasMotors &&
          $store.state.last_movement != '48' &&
          $store.state.display_modal != '' &&
          $store.state.modal == true
        "
        opacity="1"
        :class="$store.getters.skin"
        style="text-align=center"
      >
        <v-progress-circular indeterminate color="red"></v-progress-circular
        ><br /><strong style="color: red">{{
          $store.state.display_modal
        }}</strong></v-overlay
      >
      <div v-if="$store.state.direction == 'IN'" style="height: 15%">
        <v-stepper v-model="$store.state.currentStep" color="success"
          ><v-stepper-header>
            <v-stepper-step step="1" :complete="$store.state.currentStep > 1">{{
              $t("screens.welcome")
            }}</v-stepper-step>
            <v-stepper-step step="2" :complete="$store.state.currentStep > 2"
              >{{ $t("screens.customerInfos") }}
            </v-stepper-step>
            <v-stepper-step step="3" :complete="$store.state.currentStep > 3"
              >{{ $t("screens.vehicleInfos") }}
            </v-stepper-step>
            <v-stepper-step step="4" :complete="$store.state.currentStep > 4">{{
              $t("screens.comments")
            }}</v-stepper-step>
            <v-stepper-step step="5" :complete="$store.state.currentStep > 5">{{
              $t("screens.reminders")
            }}</v-stepper-step>
            <v-stepper-step step="6" :complete="$store.state.currentStep > 6">{{
              $t("screens.deposit")
            }}</v-stepper-step>
            <v-stepper-step step="7" :complete="$store.state.currentStep > 7">{{
              $t("screens.advisor")
            }}</v-stepper-step>
            <v-stepper-step step="8">{{ $t("screens.end") }}</v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </div>
      <div v-if="$store.state.direction == 'OUT'" style="height: 15%">
        <v-stepper v-model="$store.state.currentStep" color="success"
          ><v-stepper-header>
            <v-stepper-step
              step="301"
              :complete="$store.state.currentStep > 301"
              >{{ $t("screens.welcome") }}</v-stepper-step
            >
            <v-stepper-step
              step="302"
              :complete="$store.state.currentStep > 302"
              >{{ $t("screens.pickup") }}</v-stepper-step
            >
            <v-stepper-step
              step="303"
              :complete="$store.state.currentStep > 303"
              >{{ $t("screens.advisor") }}</v-stepper-step
            >
            <v-stepper-step step="304">{{ $t("screens.end") }}</v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </div>
      <router-view />
      <v-footer
        dark
        :class="$store.getters.skin"
        class="justify-center"
        style="position: absolute; bottom: 0; width: 100%"
      >
        <v-spacer />
        <v-btn
          v-if="$store.state.previousVisible"
          :disabled="!$store.state.previousEnabled"
          @click="previousStep"
          >{{ $t("main.previous") }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-if="$store.state.nextVisible"
          :disabled="!$store.getters.nextEnabled"
          @click="nextStep"
          >{{ $t("main.next") }}</v-btn
        >
        <v-spacer />
      </v-footer>
    </v-main>
    <v-footer
      dark
      :class="$store.getters.skin"
      height="30"
      class="justify-center"
    >
      <div class="d-flex align-center">{{ $t("copyright") }}</div>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  name: "App",

  components: {},

  data() {
    return {
      toto: 0,
      interval: "",
      version: process.env.PACKAGE_VERSION,
      debugmode: process.env.VUE_APP_DEBUG_BTN == "1",
      timeout: "not yet",
    };
  },
  mounted() {
    console.log(this.$route.query.style);
    const st = this.$store;
    console.log(this.$store.state.loaded);
    console.log(this.$store.state.hasMotors);
    console.log(this.$route.query.appt);
    if (
      (this.$store.state.loaded && this.$store.state.hasMotors) ||
      this.$route.query.appt
    ) {
      window.setInterval(() => {
        st.dispatch("loadstatus");
      }, 1000);
    }
  },
  created() {
    this.$store.dispatch("loadShopData").then(() => {
      try {
        require("./styles/styles." + this.$store.getters.skin + ".css");
        document.body.className = this.$store.getters.skin;
      } catch (e) {
        console.log(e);
        require("./styles/styles.ipsip.css");
        this.$store.commit({ type: "updateSkin", skin: "ipsip" });
        document.body.className = "ipsip";
      }
      this.$i18n.locale = this.$store.getters.lang;
      this.iscreated = true;
    });
  },
  methods: {
    goback() {
      this.$i18n.locale="fr";
    },
    goback2() {
      this.$i18n.locale="es";
    },
    goback3() {
      this.$i18n.locale="us";
    },
    goback4() {
      this.$store.commit({ type: "updateSkin", skin: "cit" });
      require("./styles/styles.cit.css");
    },
    goback5() {
      this.$store.commit({ type: "updateSkin", skin: "peu" });
      require("./styles/styles.peu.css");
    },
    goback6() {
      this.$store.commit({ type: "updateSkin", skin: "psa" });
      require("./styles/styles.psa.css");
    },
    goback7() {
      this.$store.commit({ type: "updateSkin", skin: "ipsip" });
      require("./styles/styles.ipsip.css");
    },
    nextStep() {
      //    const cb_conf = this.$store.state.callback;
      this.$store.dispatch("sync");
      this.$store.commit("nextStep");
      let cb_conf = this.$store.state.callback;
      console.log(this.$store.state.hasMotors);
      console.log(this.timeout);
      if (!this.$store.state.hasMotors) {
        if (this.timeout !== "not yet") window.clearTimeout(this.timeout);
        if (this.timeout != this.$store.state.timeout)
          window.clearTimeout(this.$store.state.timeout);
        this.timeout = window.setTimeout(() => {
          window.location.href = cb_conf;
        }, process.env.VUE_APP_NOMOTOR_TIMEOUT);
        this.$store.commit({ type: "timeout", timeout: this.timeout });
        console.log("reset timeout");
      } else {
        axios.get("http://localhost:3000/front");
      }
      switch (this.$store.state.currentStep) {
        case 1:
          this.$router.push("/");
          this.$store.commit("disablePrevious");
          this.$store.state.last_movement = 49;
          this.$store.commit("modal", {
            msg: this.$i18n.t("locker.preparing"),
          });
          this.$store.commit("modal_lock");
          break;
        case 2:
          this.$router.push("/cust");
          this.$store.commit("disablePrevious");
          break;
        case 3:
          this.$router.push("/vehicle");
          this.$store.state.last_movement = -1;
          this.$store.commit("disablePrevious");
          break;
        case 4:
          this.$router.push("/comments");
          this.$store.commit("disablePrevious");

          break;
        case 5:
          this.$router.push("/reminders");
          this.$store.state.last_movement = 49;

          this.$store.commit("modal", {
            msg: this.$i18n.t("locker.preparing"),
          });
          this.$store.commit("modal_lock");
          this.$store.commit("disablePrevious");
          break;
        case 6:
          this.$router.push("/keydrop");
          this.$store.commit("modal", {
            msg: this.$i18n.t("locker.presentingSoon"),
          });
          this.$store.state.last_movement = 49;
          this.$store.commit("modal_lock");
          this.$store.commit("disablePrevious");
          break;
        case 7:
          this.$router.push("/advisor");
          this.$store.commit("disablePrevious");
          break;
        case 8:
          this.$store.dispatch("sync");
          this.$store.state.last_movement = 49;
          this.$store.commit("modal", {
            msg: this.$i18n.t("locker.safeMove"),
          });
          this.$store.commit("disablePrevious");
          this.$store.commit("disableNext");
          this.$router.push("/confirmation");
          if (this.$store.state.hasMotors)
            axios.get("http://localhost:3000/frontend");
          break;
        case 301:
          this.$router.push("/");
          this.$store.commit("disablePrevious");
          break;
        case 302:
          this.$store.state.last_movement = 49;
          this.$store.commit("modal", {
            msg: this.$i18n.t("locker.preparing"),
          });
          this.$store.commit("modal_lock");
          this.$router.push("/keypick");
          this.$store.commit("disablePrevious");
          break;
        case 303:
          this.$router.push("/advisor");
          this.$store.commit("disablePrevious");
          break;
        case 304:
          this.$store.state.last_movement = 49;
          this.$store.commit("modal", {
            msg: this.$i18n.t("locker.resetMove"),
          });
          this.$store.commit("modal_lock");
          this.$router.push("/confirmation");
          this.$store.commit("disableNext");
          if (this.$store.state.hasMotors)
            axios.get("http://localhost:3000/frontend");
          break;
        default:
          this.$router.push("/");
          break;
      }
    },
    previousStep() {
      // TODO: reenable previous button where possible
      const cb_conf = this.$store.state.callback;
      this.$store.dispatch("sync");
      this.$store.commit("previousStep");
      switch (this.$store.state.currentStep) {
        case 1:
          this.$router.push("/");
          this.$store.commit("disablePrevious");
          break;
        case 2:
          this.$router.push("/cust");
          this.$store.commit("disablePrevious");
          break;
        case 3:
          this.$router.push("/vehicle");
          this.$store.commit("enablePrevious");
          break;
        case 4:
          this.$router.push("/comments");
          break;
        case 5:
          this.$router.push("/reminders");
          break;
        case 6:
          this.$router.push("/keydrop");
          break;
        case 7:
          this.$router.push("/advisor");
          break;
        case 8:
          this.$store.dispatch("sync");
          this.$store.commit("disableNext");
          this.$router.push("/confirmation");
          window.setTimeout(() => {
            window.location.href = cb_conf;
          }, 10000);
          break;
        default:
          this.$router.push("/");
          break;
      }
    },
  },
};
</script>
<style lang="sass">
@import '../node_modules/typeface-roboto/index.css'
</style>
